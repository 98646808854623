import React from 'react';
import styled, { css } from 'styled-components';
import PropTypes from 'prop-types';

class ZionBanner extends React.Component {
  render() {
    const { publication } = this.props;
    const { titleBanner: title, titleAlign, bgColor = null, titlePosition, children, borderLess } = publication;

    if (!children) {
      return false;
    }

    const showTitleOnFooter = titlePosition && titlePosition === 'Footer';

    return (
      <Wrapper bgColor={bgColor}>
        <Panel borderLess={borderLess}>
          {title && !showTitleOnFooter && (
            <Heading titleAlign={titleAlign}>
              <Title className="panel-title" titleAlign={titleAlign}>
                {title}
              </Title>
            </Heading>
          )}
          {children.map(child => (
            <div key={child._id}>{this.props.renderComponent(child)}</div>
          ))}
          {title && showTitleOnFooter && (
            <Footer titleAlign={titleAlign}>
              <Title className="panel-title" titleAlign={titleAlign}>
                {title}
              </Title>
            </Footer>
          )}
        </Panel>
      </Wrapper>
    );
  }
}

ZionBanner.propTypes = {
  publication: PropTypes.object,
  renderComponent: PropTypes.func,
};

const Wrapper = styled.div`
  ${({ bgColor }) => bgColor && `background-color: ${bgColor}`};
`;

const Panel = styled.div`
  ${({ borderLess }) => (borderLess === 'sem borda' ? `padding: 0px;` : `padding: 20px 12px`)};
  width: 100%;
`;

const flex = {
  ['Esqueda']: css`
    justify-content: flex-start;
  `,
  ['Direita']: css`
    justify-content: flex-end;
  `,
  ['Centro']: css`
    justify-content: center;
  `,
};
const TitleContainer = styled.div`
  ${({ titleAlign }) => css`
    display: flex;
    align-items: center;
    ${titleAlign && flex[titleAlign]}
  `}
`;

const Heading = styled(TitleContainer)`
  margin-bottom: 20px;
`;

const Footer = styled(TitleContainer)`
  margin-top: 20px;
`;

const align = {
  ['Esqueda']: css`
    text-align: left;
  `,
  ['Direita']: css`
    text-align: right;
  `,
  ['Centro']: css`
    text-align: center;
  `,
};

const Title = styled.h2`
  ${({ titleAlign }) => css`
    font-size: 26px;
    white-space: pre-wrap;
    color: #666;
    font-weight: 700;
    line-height: 1.2em;
    width: fit-content;
    text-transform: lowercase;
    ${titleAlign && align[titleAlign]}
  `}
`;

export default ZionBanner;
